import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import Datepicker from '@vuepic/vue-datepicker'
import TableLite from 'vue3-table-lite'
import Multiselect from '@vueform/multiselect'
//import VueQrcode from '@chenfengyuan/vue-qrcode'
import QrcodeVue from 'qrcode.vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { createI18n } from 'vue-i18n'
import tc from './lang/tc.json'
import en from './lang/en.json'
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(far)
import { dom } from "@fortawesome/fontawesome-svg-core"
dom.watch()
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@vuepic/vue-datepicker/dist/main.css'
//import "bootstrap"
import mitt from 'mitt'
import JsonExcel from 'vue-json-excel'
import Vue3SimpleHtml2pdf from 'vue3-simple-html2pdf'
import VueHtml2pdf from 'vue3-html2pdf'
import VueSignaturePad from 'vue-signature-pad'
import { commonfunctions } from '@/commonfunctions.js'
import './registerServiceWorker'
import 'devextreme/dist/css/dx.light.css'
const emitter = mitt()
const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || 'tc',
  globalInjection: true,
  fallbackLocale: 'tc',
  messages: {
    tc: tc,
    en: en,
  },
})

import Echo from 'laravel-echo'
 
window.Pusher = require('pusher-js')
 
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    cluster: 'ap1',
    forceTLS: false,
    disableStats: true
})

axios.defaults.withCredentials = false
//axios.defaults.baseURL = 'http://192.168.1.17:10003/api/auth/'
const app = createApp(App)
app.config.productionTip = false
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Vuex)
app.use(BootstrapVue3)
app.use(VueAxios, axios)
app.use(i18n)
app.use(JsonExcel)
app.use(Vue3SimpleHtml2pdf)
app.use(VueHtml2pdf)
app.use(VueSignaturePad)
app.provide('icons', icons)
app.component('Multiselect', Multiselect)
app.component('CIcon', CIcon)
app.component('Datepicker', Datepicker)
app.component('TableLite', TableLite)
app.component('QrcodeVue', QrcodeVue)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('VueCal', VueCal)
//app.component(VueQrcode.name, VueQrcode)
app.config.globalProperties.emitter = emitter

app.mount('#app')
app.mixin(commonfunctions)
router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', 'tc')
  }
  var api_baseURL = 'http://127.0.0.1:8000/api/'
  var current_hostname = window.location.hostname
  if (current_hostname == 'localhost' || current_hostname == 'eportal.ankh.local' || current_hostname == 'crm_tommy.ankh.local') {
    api_baseURL = process.env.VUE_APP_API_BASE_URL_DEV
  } else if (current_hostname == 'center2.ankh.com.hk'){
    api_baseURL = process.env.VUE_APP_API_BASE_URL_PRD_BK
  } else {
    api_baseURL = process.env.VUE_APP_API_BASE_URL_PRD
  }
  axios.defaults.baseURL = api_baseURL
    if (to.path != '/') {
      next()
    } else {
      next('/')
    }
})
